/* Slider Image Styling */
img.logo_img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

/* Slider Container */
.slider {
    margin-top: 20px;
    background-color: whitesmoke;
    padding: 4em 4em;
    overflow: hidden; /* Hide overflow to keep the slider clean */
}

/* Slide Track */
.slide-track {
    display: flex;
    gap: 3em;
    width: calc(200% + 3em); /* Adjust width to fit all slides */
    animation: scroll 20s linear infinite; /* Smooth scrolling animation */
}

/* Individual Slide */
.slide {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Scroll Animation */
@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } /* Move by half the width of the slide-track */
}

/* Pause Animation on Hover */
.slider:hover .slide-track {
    animation-play-state: paused;
}

/* Seamless Loop */
