#main.home-wrapper {
  position: relative;
  overflow: hidden;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: -1; 
}

#main {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
}

.white-text {
  color: white;
}

.text-center {
  text-align: center;
}

.lead {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.logo {
  max-width: 300px;
  margin-bottom: 20px;
}

.main-button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #007bff; 
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.main-button:hover {
  background-color: #0056b3; 
}

@media (max-width: 768px) {
  #main.home-wrapper {
    height: auto;
    padding: 20% 1%;
  }

  .lead {
    font-size: 1.2em;
  }

  .logo {
    max-width: 200px;
  }
}
