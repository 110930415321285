/* Custom Card Styles */
.custom-card {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.custom-card:hover {
    transform: translateY(-10px);
}

/* Image Wrapper for Blur Effect */
.card-img-wrapper {
    overflow: hidden;
}

/* Applying a blur effect */
.card-img-top {
    transition: transform 0.5s ease, filter 0.5s ease;
    width: 100%;
    height: auto;
}

/* Blur Effect on Hover */
.card-img-wrapper:hover .card-img-top {
    transform: scale(1.1);
    filter: blur(5px);
}

/* Card Body Styles */
.card-body {
    padding: 20px;
    background-color: #fff;
}

/* Card Title */
.card-title {
    font-size: 1.5em;
    margin-bottom: 15px;
}

/* Card Text */
.card-text {
    margin-bottom: 20px;
    font-size: 1em;
    color: #6c757d;
}

/* Button Style */
.btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
}