
  
  .sec-icon {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0 auto;
  }
  
  .sec-icon::before {
    content: "";
    position: absolute;
    height: 1px;
    left: -70px;
    margin-top: -5.5px;
    top: 60%;
    background: #333333;
    width: 50px;
  }
  
  .sec-icon::after {
    content: "";
    position: absolute;
    height: 1px;
    right: -70px;
    margin-top: -5.5px;
    top: 60%;
    background: #333;
    width: 50px;
  }
  
  .advertisers-service-sec {
    background-color: #f5f5f5;
  }
  
  .advertisers-service-sec span {
    color: rgb(255, 23, 131);
  }
  
  .advertisers-service-sec .col {
    padding: 0 1em 1em 1em;
    text-align: center;
  }
  
  .advertisers-service-sec .service-card {
    width: 100%;
    height: 100%;
    padding: 2em 1.5em;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
  }
  
  .advertisers-service-sec .service-card::after {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(#fba71c,rgb(243, 102, 34));
    position: absolute;
    left: 0%;
    top: -98%;
    z-index: -2;
    transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
  }
  
  .advertisers-service-sec h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
    z-index: 3;
  }
  
  .advertisers-service-sec p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    z-index: 3;
  }
  
  .advertisers-service-sec .icon-wrapper {
    background-color: #2c7bfe;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
    z-index: 3;
  }
  
  .advertisers-service-sec .service-card:hover:after {
    top: 0%;
  }
  
  .service-card .icon-wrapper {
    background-color: #ffffff;
    color: rgb(243, 102, 34);
  }
  
  .advertisers-service-sec .service-card:hover .icon-wrapper {
    color: #0dcaf0;
  }
  
  .advertisers-service-sec .service-card:hover h3 {
    color: #ffffff;
  }
  
  .advertisers-service-sec .service-card:hover p {
    color: #f0f0f0;
  }
  /* ADVERTISERS SERVICE CARD ENDED */
  