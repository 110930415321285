#footer {
    background-color: #1c222f;
    padding: 10px 10px;
}

#footer p a{
    text-decoration: none;
	color: #FFF;
}

#footer p a:hover{
	color: #f36622; 
}

#footer img{
	max-height: 40px; 
}

/* footer scocial icon */

.footer-social {
	text-align:right;
}

.footer-social li {
	display:inline-block;
	margin-left:10px;
}

.footer-social li a {
	display:block;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	border-radius:50%;
	color:#FFF;
	background-color:#EBEBEB;
	-webkit-transition:0.2s opacity;
	transition:0.2s opacity;
}

.footer-social li a.facebook {
	background-color:#3b5998;
}

.footer-social li a.twitter {
	background-color:#55acee;
}

.footer-social li a.google-plus {
	background-color:#dd4b39;
}

.footer-social li a.instagram {
	background-color:#e95950;
}

.footer-social li a.youtube {
	background-color:#ff0000;
}

.footer-social li a.linkedin {
	background-color:#007bb5;
}