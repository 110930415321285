/* Main Section */
#cta.section {
  position: relative;
  overflow: hidden;
  /* height: 250px; */
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start (left) */
  text-align: left; /* Align text to the left */
  background-image: url('../../assets/photo_1.jpg'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
  padding: 0 20px; /* Add padding to prevent content from touching edges */
}

/* Background overlay */
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
}

/* Text styles */
.white-text {
  color: white;
}

.lead {
  font-size: 1em;  
  margin-bottom: 15px; /* Adjusted margin */
}

.logo {
  max-width: 150px; /* Reduced size */
  margin-bottom: 20px;
  margin-top: 1%; /* Adjusted margin */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  #cta.section {
    height: auto;
    padding: 40px 20px;
    justify-content: center; /* Center align items on mobile */
    text-align: center; /* Center text on mobile */
  }

  .lead {
    font-size: 1em; /* Further reduced font size for mobile */
    margin-bottom: 10px; /* Reduced margin */
  }

  .logo {
    margin-left: auto; /* Center logo horizontally */
    margin-right: auto; /* Center logo horizontally */
    max-width: 150px;
    margin-top: 1%;
  }
}

/* Responsive adjustments for tablet screens */
@media (min-width: 769px) and (max-width: 992px) {
  #cta.section {
    height: auto;
    padding: 20px;
    justify-content: flex-start; /* Align items to the start (left) */
    text-align: left; /* Align text to the left */
  }

  .lead {
    font-size: 1.1em; /* Adjusted font size for tablets */
    margin-bottom: 12px; /* Adjusted margin for tablets */
  }

  .logo {
    max-width: 120px; /* Adjust logo size for tablets */
    margin-top: 1%;
  }
}
