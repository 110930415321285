#header {
    position: fixed;
    width: 100%;
    padding: 10px 0;
    top: 0; 
    left: 0;
    z-index: 1030; 
  }
  
  #header .navbar-toggler {
    background-color: white;
  }
  
  #header .navbar-brand img {
    max-height: 40px; 
  }
  
  #header .navbar-nav .nav-link {
    color: #FFF; 
  }
  
  #header .navbar-nav .nav-link:hover {
    color: #f36622; 
  }
  
  /* Dropdown Menu */
  #header .dropdown-menu {
    border: none !important; /* Remove border */
  }
  
  #header .dropdown-menu a:hover {
    color: #f36622;
  }
  
  
  