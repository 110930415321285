
@import '@fortawesome/fontawesome-free/css/all.min.css';

/* @font-face { */
    /* font-family: 'Poppins';
    src: url('./fonts/Poppins-Regular.ttf') format('truetype'),
         url('/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal; */
/* } */

*{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* common classes */

/* Adding a gradient overlay */
.background-overlay:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#374050), to(#798696));
    background-image: linear-gradient(to bottom, #374050 0%, #798696 100%);
    opacity: 0.5;
  }

 /* Button Styles */
.main-button {
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    background-color: #FF6700 !important;
    border: 2px solid transparent;
    border-radius: 40px;
    color: #FFF !important;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    text-decoration: none;
}

.main-button:hover, .main-button:focus {
    background-color: #fff;
    border: 2px solid #FBA71C;
    color: #FF6700;
}

.main-button.icon-button:hover, .main-button.icon-button:focus {
    padding-right: 45px;
}

.main-button.icon-button:after {
    content: "\f178";
    font-family: FontAwesome;
    position: absolute;
    width: 30px;
    right: 15px;
    text-align: center;
    opacity: 0;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.main-button.icon-button:hover:after, .main-button.icon-button:focus:after {
    opacity: 1;
}
