.founder-info {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .founder-image img {
    max-width: 150px;
    height: auto;
    border-radius: 50%; 
    margin-right: 20px; 
  }
  
  .founder-details {
    max-width: 600px; 
  }
  
  .founder-details h2, 
  .founder-details h3, 
  .founder-details p {
    margin: 0 0 10px 0; 
  }
  
  .founder-details ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
  }
  
  .founder-details ul li a {
    text-decoration: none;
    color: #007bff; 
  }
  
  .founder-details ul li a:hover {
    text-decoration: underline;
  }
  