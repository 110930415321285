.service-component-container {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
    border-radius: 16px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    backdrop-filter: blur(10px); /* Glassmorphic blur effect */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border to enhance the glass effect */
}

.service-img {
    transition: filter 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the image */
}

.service-img:hover {
    filter: blur(1px);
}

.service-component {
    padding: 20px;
    border-radius: 8px;
    background-color: transparent; /* Use transparent background as the container handles the glassmorphic effect */
}
