.logo-left {
    width: 150px;  
    height: 120px; 
  }

.logo-right {
    width: 180px;  
    height: 120px;
    float: right;
    margin-left: 0; 
  }

  
  .Aon_logo{
    height: 149px;
    width: 129px;
}

  
 
  
 