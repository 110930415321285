.not-found-container {
    padding: 100px 20px;
    background-color: #f8f9fa;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .not-found-container .display-1 {
    font-size: 6rem;
    color: #dc3545;
  }
  
  .not-found-container .lead {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .not-found-container .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
  }
  