/* Main Section */
#cta.section {
  position: relative;
  overflow: hidden;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('../../assets/photo_1.jpg'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed; 
}

/* Background overlay */
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; 
}

#cta {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
}

/* Text styles */
.white-text {
  color: white;
}

.text-center {
  text-align: center;
}

.lead {
  font-size: 1.2em;  /* Reduced font size */
  margin-bottom: 15px; /* Adjusted margin */
}

.logo {
  max-width: 250px; /* Reduced size */
  margin-bottom: 15px; /* Adjusted margin */
}

.main-button {
  padding: 8px 16px; /* Reduced padding */
  font-size: 0.9em; /* Slightly smaller font size */
  color: white;
  background-color: #007bff; 
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.main-button:hover {
  background-color: #0056b3; 
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  #main.section {
    height: auto;
    padding: 40px 20px;
  }

  .lead {
    font-size: 1em; /* Further reduced font size for mobile */
    margin-bottom: 10px; /* Reduced margin */
  }

  .logo {
    max-width: 200px;
  }
}
